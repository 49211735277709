import React from 'react'
import Navbar from '../components/navbar'
import FirstBlock from '../components/firstBlock'
import Benefits from '../components/benefits'
import DebitCard from '../components/debitCard'
import Contact from '../components/contact'
import Faq from '../components/faq'
import Footer from '../components/footer'

export default function Landing() {
  return (
    <>
      <Navbar />
      <FirstBlock />
      <Benefits />
      <Contact />
      <Footer />
    </>
  )
}