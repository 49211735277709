import React from 'react'
import { Box, Container, Grid, createStyles, makeStyles } from '@material-ui/core'
import Cellphone from '../assets/safrainvest.png'
//import Cellphone from '../assets/conta-internacional.png'

const useStyles = makeStyles((theme) => createStyles({
  heightContainer: {
    [theme.breakpoints.up('md')]: {
      minHeight: 481
    }
  },
  box: {
    backgroundColor: '#ffb200',
    [theme.breakpoints.up('md')]: {
      minHeight: 481
    }
  },
  gridImage: {
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(15),
      marginBottom: theme.spacing(6)
    },
    [theme.breakpoints.up('md')]: {
      position: 'relative',
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0)
      //top: 52
    }    
  },
  gridText: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(6)
    },
    [theme.breakpoints.down('md')]: {
      textAlign: 'center'
    }    
  },
  image: {
    width: 400,
    [theme.breakpoints.down('md')]: {
      width: 300,
    },
  },
  title: {
    fontSize: 44,
    letterSpacing: '1.1',
    color: '#1b2e62',
    fontFamily: 'Lato',
    fontWeight: 800
  },
  titleSpace: {
    lineHeight: '61px'
  },
  subTitle: {
    fontFamily: 'Lato',
    fontSize: 21,
    fontweight: 900,
    letterSpacing: '0.53',
    color: '#000000',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
  },
  subTitleLast: {
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(6)
    }    
  }
}))

export default function FirstBlock() {
  const classes = useStyles()

  return(
    <Box className={classes.box}>
      <Container className={classes.heightContainer}>
        <Grid container alignItems="center" className={classes.heightContainer}>
          <Grid item xs={12} md={5} className={classes.gridImage}>
            <img src={Cellphone} alt="Conta internacional" className={classes.image} />  
          </Grid>

          <Grid item xs={12} md={7} className={classes.gridText}>
            <Box className={classes.title}>
              <Box component="span" className={classes.titleSpace}>
                Agora a Supercambio
              </Box>
              <br />
              <Box component="span" className={classes.titleSpace}>
                também é Investimento.
              </Box>               
            </Box>
            <Box pt={4.5} className={classes.subTitle}>
              Parceria com o Safra Invest para oferecer a melhor solução 
            </Box>
            <Box pb={2} className={[classes.subTitle, classes.subTitleLast]}>
              em assessoria financeira para nossos clientes.
            </Box>
          </Grid>

        </Grid>
      </Container>
    </Box>
  )
}