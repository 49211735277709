import React from 'react'
import { Box, Container, Grid, Hidden } from '@material-ui/core'
import ImageContact from './contactComponents/image'
import FormContact from './contactComponents/form'

export default function Contact() {
  return(
    <Box mt={10}>
      <Container>
        <Hidden only={['xs', 'sm']}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <FormContact />
            </Grid>

            <Grid item xs={12} md={6}>
              <ImageContact />
            </Grid>
          </Grid>
        </Hidden>

        <Hidden only={['lg', 'md', 'xl']}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <ImageContact />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormContact />
            </Grid>
          </Grid>
        </Hidden>
      </Container>
    </Box>
  )
}