import React, { useState } from 'react'
import { Box, Container, createStyles, makeStyles, Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: '80%',
    }    
  },
  box: {
    [theme.breakpoints.down('md')]: {
      textAlign: 'center'
    }
  },
  heading: {
    flexBasis: '100%',
    flexShrink: 1,
    fontFamily: 'Lato',
    fontSize: 18,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.45,
    color: '#626262',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  title: {
    fontFamily: 'Lato',
    fontSize: 25,
    fontWeight: 800,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.63,
    color: '#1b2e62',
    textTransform: 'uppercase'
  },
  accordeon: {
    border: '2px solid #050655',
    marginBottom: theme.spacing(2)
  },
  expandMore: {
    fontSize: 50,
    color: '#010254'
  }, 
  linkAccordeon: {
    textDecoration: 'none',
    color: '#010254',
  }
}))

export default function Faq() {
  const classes = useStyles()

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const fares = <a href="https://www.bancobs2.com.br/wp-content/uploads/2020/07/banco-bs2-tarifas_conta-internacional_julho2020.pdf" target="_blank" rel="noopener noreferrer" className={classes.linkAccordeon}>Clique aqui </a>
  const faresTable = <a href="https://www.bancobs2.com.br/wp-content/uploads/2020/07/banco-bs2-tarifas_conta-internacional_julho2020.pdf" target="_blank" rel="noopener noreferrer" className={classes.linkAccordeon}>tabela de tarifas </a>

  return(
    <Box mt={10.5} className={classes.box}>
      <Container>
        <Box className={classes.title}>
          Perguntas Frequentes
        </Box>
        <Box mt={6.2} mb={13} className={classes.root}>          
          <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className={classes.accordeon}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className={classes.expandMore} />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography className={classes.heading}>O que é a conta internacional?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                A Conta internacional é uma conta corrente digital em moeda estrangeira, exclusiva para correntistas do BS2. Ela permite enviar e receber transferências internacionais, e também converter saldo entre contas BS2 de forma instantânea. Além disso, você tem acesso a um cartão de débito para compras e saques no exterior, em estabelecimentos físicos ou lojas online.
              </Typography>
            </AccordionDetails>
          </Accordion>
          
          <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} className={classes.accordeon}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className={classes.expandMore} />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography className={classes.heading}>Quais as vantagens de se ter uma Conta Internacional no BS2?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                A Conta internacional no BS2 te permite ter saldo em dólar de maneira prática, rápida e livre de manutenção. Confira as vantagens:
                <ul>
                  <li> Abertura online, 100% digital;</li>
                  <li> Sem tarifa de abertura ou mensalidades;</li>
                  <li> Conversão instantânea de moedas entre contas, a qualquer momento e horário do dia;</li>
                  <li> Cartão de débito Mastercard para compras em estabelecimentos físicos e online;</li>
                  <li> Envio e recebimento de ordens de pagamento;</li>
                  <li> Saques em qualquer lugar do mundo em ATMs vinculados à rede Cirrus;</li>
                  <li> Transferência grátis e instantânea para outras contas internacionais BS2.</li>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>
          
          <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} className={classes.accordeon}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className={classes.expandMore} />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Typography className={classes.heading}>Para quem é indicada a conta internacional?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <Box component="p">
                  Ideal para turistas frequentes, intercambistas, pessoas que possuem negócios ou patrimônio fora do Brasil e recebem por meio de plataformas digitais como Google AdSense;
                </Box>

                <Box component="p">
                  Para quem necessita de uso constante de moeda estrangeira e brasileiros que residem temporariamente no exterior.
                </Box>

                <Box component="p">
                  Não válida para expatriados ou não residentes.
                </Box>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} className={classes.accordeon}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className={classes.expandMore} />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography className={classes.heading}>A conta internacional BS2 fica no exterior?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Temos uma agência internacional que nos autoriza a oferta desse produto e nos qualifica em total conformidade com todas as regulamentações, brasileiras e internacionais, dos órgãos competentes.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} className={classes.accordeon}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className={classes.expandMore} />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography className={classes.heading}>Quais são as tarifas da conta internacional?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <Box component="p">
                  A conta internacional BS2 é isenta de tarifa de manutenção, porém alguns serviços avulsos são tarifados.
                </Box>
                
                <Box component="p">
                  {fares} e veja a tabela completa.
                </Box>                
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')} className={classes.accordeon}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className={classes.expandMore} />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography className={classes.heading}>Tem cartão para a conta internacional? Como posso solicitar?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <Box component="p">
                  Com a conta internacional você pode ter um cartão de débito para realizar transações fora do país.
                </Box>

                <Box component="p">
                  Solicite o seu em Conta Internacional > Debit Card.                  
                </Box>

                <Box component="p">
                  É necessário transacionar no mínimo 30 dólares em sua conta através de conversão entre contas e/ou recebimento de ordens para solicitar a primeira via do cartão.                  
                </Box>
              </Typography>
            </AccordionDetails>
          </Accordion>


          <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')} className={classes.accordeon}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className={classes.expandMore} />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography className={classes.heading}>Tem tarifa para solicitar o cartão da conta internacional?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Não existe nenhuma tarifa cobrada na emissão da primeira via do debit card.
              </Typography>
            </AccordionDetails>
          </Accordion>


          <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')} className={classes.accordeon}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className={classes.expandMore} />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography className={classes.heading}>Quanto de IOF pago pela conversão da minha conta em reais para dólares?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <Box component="p">
                  Para a conversão do saldo da sua conta BS2 em reais para a sua conta internacional em dólares, você pagará IOF (Imposto sobre Operações Financeiras) de 1,1%, e de seu saldo em dólares para reais, o IOF será de 0,38%.
                </Box>

                <Box component="p">
                  O valor efetivo total, incluindo o IOF, será exibido na tela do app no momento da conversão.
                </Box>
              </Typography>
            </AccordionDetails>
          </Accordion>


          <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')} className={classes.accordeon}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className={classes.expandMore} />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography className={classes.heading}>Para a conversão entre contas, qual o spread? E qual o dólar utilizado?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <Box component="p">
                  Nas operações de conversão entre contas (de real para dólar e vice-versa), o spread de câmbio é de 2% sobre a nossa última cotação do dólar comercial.
                </Box>

                <Box component="p">
                  Você poderá ver na tela do app a taxa de câmbio, o IOF e o VET (Valor Efetivo Total) antes de efetivar a operação.
                </Box>
              </Typography>
            </AccordionDetails>
          </Accordion>


          <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')} className={classes.accordeon}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className={classes.expandMore} />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography className={classes.heading}>Posso sacar em qualquer moeda, ou apenas em dólares?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Você poderá sacar em ATM vinculados à rede Cirrus em qualquer moeda no mundo. Neste caso, a conversão se dará diretamente entre dólar (da sua conta internacional) e a moeda local, como por exemplo, o Euro caso esteja na Europa.
              </Typography>
            </AccordionDetails>
          </Accordion>


          <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')} className={classes.accordeon}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className={classes.expandMore} />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography className={classes.heading}>Quais são os limites transacionais da conta internacional?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <Box component="p">
                  Câmbio entre Contas BS2
                </Box>

                <ul>
                  <li> Valor máximo por transação: US$ 10 mil;</li>
                  <li> Valor diário: US$ 10 mil;</li>
                  <li> Limite anual inicial do cliente: US$ 20 mil.</li>
                </ul>

                <Box component="p">
                  Envio de Ordem de Pagamento
                </Box>

                <ul>
                  <li> Valor diário por transação: US$ 10 mil.</li>
                </ul>

                <Box component="p">
                  Para solicitar aumento do limite anual de câmbio entre contas BS2, entre em contato com nossa central de relacionamento. Os demais limites não podem ser alterados.
                </Box>
              </Typography>
            </AccordionDetails>
          </Accordion>


          <Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')} className={classes.accordeon}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className={classes.expandMore} />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography className={classes.heading}>Posso transferir para outras contas internacionais BS2?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <Box component="p">
                  Sim. Basta utilizar o botão “Transferir para outra conta BS2 internacional”, a partir da opção “Transferir” do menu da conta internacional, munido apenas do número do CPF da pessoa com conta internacional BS2 a quem deseja transferir.
                </Box>

                <Box component="p">
                  Não há custo para esta transação, ela é instantânea e pode ser feita a qualquer hora do dia.
                </Box>
              </Typography>
            </AccordionDetails>
          </Accordion>


          <Accordion expanded={expanded === 'panel13'} onChange={handleChange('panel13')} className={classes.accordeon}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className={classes.expandMore} />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography className={classes.heading}>E para transferir para outras contas internacionais de outros bancos?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <Box component="p">
                  Para transferências entre contas internacionais de outros bancos, deve ser usado o botão “Transferir para outros bancos”, a partir da opção “Transferir” do menu da conta internacional.
                </Box>

                <Box component="p">
                  Lembre-se de preencher corretamente os dados da conta para quem deseja transferir, como: nome do banco onde o beneficiário possui conta, código Swift/Aba deste banco, nome do beneficiário, dados da conta ou IBAN, e nome e Swift/Aba do banco intermediário, quando houver.                  
                </Box>

                <Box component="p">
                  O banco intermediário é normalmente utilizado para contas com saldo em dólar, mas que não estão localizadas nos EUA, e não é o mesmo banco que o do beneficiário – é somente um intermediador da transação. Esta informação deve ser fornecida pelo próprio beneficiário, junto aos dados de sua conta.                  
                </Box>

                <Box component="p">
                  Cabe lembrar que esta transação é tarifada, consulte a {faresTable}. O envio de ordem para outros bancos também está suscetível a deduções de despesas pelo banco destinatário e/ou intermediário do destinatário.                  
                </Box>
              </Typography>
            </AccordionDetails>
          </Accordion>

        </Box>        
      </Container>
    </Box>
  )
}