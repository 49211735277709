import React from 'react'
import { Box, Container, createStyles, Grid, makeStyles } from '@material-ui/core'
import BS2Card from '../assets/cartao-bs-2.png'

const useStyles = makeStyles((theme) => createStyles({
  title: {
    fontFamily: 'Lato',
    fontSize: 40,
    fontWeight: 800,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 1,
    color: '#1b2e62'
  },
  subtitle: {
    fontFamily: 'Lato',
    fontSize: 22,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.55,
    color: '#767676'
  },
  image: {
    width: '100%'
  },
  debitCardBox: {
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      paddingTop: theme.spacing(5),
      textAlign: 'center'
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(6)
    }
  }
}))


export default function DebitCard() {
  const classes = useStyles();

  return(
    <Box mt={15}>
      <Container>
        <Grid container>
          <Grid item xs={12} md={5}>
            <img src={BS2Card} alt="Cartão BS2" className={classes.image} />
          </Grid>

          <Grid item xs={12} md={7}>
            <Box className={classes.debitCardBox}>
              <Box className={classes.title}>
                Cartão de Débito Internacional
              </Box>

              <Box className={classes.subtitle}>
                <Box pt={5}>
                  Com a conta internacional você pode ter um cartão de débito para realizar transações fora do país. 
                </Box>

                <Box pt={5}>
                  Antes de solicitar a primeira via do cartão é preciso ter transacionado o valor mínimo de USD30,00 (trinta dólares) em sua conta internacional (através de conversão entre contas e/ou recebimento de ordens). Esse valor corresponde a soma dos valores que entraram em sua conta. Não é preciso ter o valor como saldo ou transacionar de uma só vez para solicitar o seu cartão.
                </Box>
              </Box>  
            </Box>
            
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}