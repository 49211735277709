import axios from 'axios';

const api = axios.create({
  baseURL: "https://0zrgmb8hwh.execute-api.us-east-1.amazonaws.com",
});

axios.defaults.headers.post['Accept'] =  "application/json";
axios.defaults.headers.post['Content-Type'] ='application/json';
axios.defaults.headers.post['Cache-Control'] = 'no-cache';

// api.interceptors.request.use(async config => {
// 	config.headers = {
//     'Content-Type': 'application/json',
//     'Access-Control-Allow-Origin': '*',
//     'Cache-Control': 'no-cache'
// 	}

//   return config;
// });


export default api;
