import React from 'react'
import { Box, Container, Divider, Typography, Grid, makeStyles, createStyles } from '@material-ui/core'
import MoneyIcon from '../assets/money.png'
import TimeIcon from '../assets/time.png'
import CreditcardIcon from '../assets/credit-card.png'
import AirplaneIcon from '../assets/airplane.png'

const useStyles = makeStyles((theme) => createStyles({
  titleBox: {
    textAlign: 'center',
    fontSize: 26,
    color: '#1b2e62',
    fontFamily: 'Lato',
    fontWeight: 800,
    letterSpacing: 0.65,
    textTransform: 'uppercase',
    marginTop: theme.spacing(7.5)
  },
  divider: {
    backgroundColor: '#1c3058',
    border: '7px solid #1c3058',
    width: 104,
    height: 0,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  grid: {
    textAlign: '-webkit-center',
  },
  boxBenefits: {
    width: 425,
    height: 388,
    border: '1px solid #524a39',
    textAlign: '-webkit-center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
      paddingBottom: theme.spacing(2)
    }
  },
  title: {
    fontFamily: 'Lato',
    fontSize: 25,
    fontWeight: 900,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.63,
    textAlign: 'center',
    color: '#1b2e62'
  },
  subtitle: {
    fontFamily: 'Lato',
    fontSize: 22,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.55,
    textAlign: 'center',
    color: '#787878',
  }
}))

export default function Benefits() {
  const classes = useStyles()

  return(
    <Box mb={3}>
      <Container>
        <Typography variant="h1" className={classes.titleBox}>
          Confira as vantagens       
        </Typography>
        <Box pt={2} pb={8}>
          <Divider flexItem={false} className={classes.divider} />
        </Box>   
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12} md={6}>
            <Box className={classes.boxBenefits}>
              <Box pt={6} pb={4.5}>
                <img src={MoneyIcon} alt="money" />
              </Box>
              <Box pl={7} pr={7} pb={3} className={classes.title}>
                Tradição e Segurança do Banco Safra
              </Box>
              <Box pl={6} pr={6} className={classes.subtitle}>
                Com 180 anos de história, hoje o Banco Safra possui R$ 233 bilhões de ativos sob gestão
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box className={classes.boxBenefits}>
              <Box pt={6} pb={4.5}>
                <img src={TimeIcon} alt="money" />
              </Box>
              <Box pl={6} pr={6} pb={3} className={classes.title}>
                Ampla disponibilidade de produtos de Investimento:
              </Box>
              <Box pl={6} pr={6} className={classes.subtitle}>
                Tesouro Direto, Renda Fixa, Renda Variável, Fundos de Investimento, Fundos Imobiliários, entre outros.
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box className={classes.boxBenefits}>
              <Box pt={6} pb={4.5}>
                <img src={CreditcardIcon} alt="money" /> 
              </Box>
              <Box pl={7} pr={7} pb={3} className={classes.title}>
                Plataforma 100% Digital
              </Box>
              <Box pl={5} pr={5} className={classes.subtitle}>
                Todos nosso produtos e soluções podem ser acessado com apenas um clique através do aplicativo.
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box className={classes.boxBenefits}>
              <Box pt={6} pb={4.5}>
                <img src={AirplaneIcon} alt="money" />
              </Box>
              <Box pl={7} pr={7} pb={3} className={classes.title}>
                Muito Além de Investimentos
              </Box>
              <Box pl={6} pr={6} className={classes.subtitle}>
                Trabalhamos com todos os produtos do Banco Safra: cartões, financiamento de veículos e imobiliário, etc.
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}