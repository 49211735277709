import React from 'react'
import clsx from 'clsx'
import { Box, Container, createStyles, makeStyles, Grid, Button } from '@material-ui/core'
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    backgroundColor: '#2A2D39',
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      textAlign: 'center'
    }
  },
  title: {
    fontFamily: 'Lato',
    color: '#f5a623',
    fontWeight: 700,
    fontSize: 18,
    marginBottom: theme.spacing(2)
  },
  link: {
    fontFamily: 'Lato',
    color: '#ffffff',
    fontSize: 15,
    fontWeight: 400,
    marginBottom: theme.spacing(1.5),
    textDecoration: 'none'
  },
  btnWhatsapp: {
    color: '#ffffff',
    borderColor: '#ffffff',
    textDecoration: 'none',
    "&:visited": { color: "#ffffff" },
    "&:hover": { color: "#ffffff" },
  },
  whatsIconBtn: {
    fontSize: 15,
    marginRight: theme.spacing(1)
  },
  icons: {
    textAlign: 'center'
  },
  iconFooter: {
    fontSize: 60,
    color: '#f5a623',
  },
  iconWhatappFooter: {
    color: '#4EC155'
  },
  supercambioData: {
    color: '#ffffff',
    textAlign: 'center',
    fontFamily: 'Lato',
    fontSize: 14
  },
  divider: {
    backgroundColor: '#ffffff'
  }
}))

export default function Footer() {
  const classes = useStyles()

  const whatsappIconFooter = <a href="https://api.whatsapp.com/send?phone=5511942282300&text=Ol%C3%A1%20operador%20Superc%C3%A2mbio!" target="_blank" rel="noopener noreferrer"><WhatsAppIcon className={clsx(classes.iconFooter, classes.iconWhatappFooter) } /></a>
  const facebook = <a href="https://www.facebook.com/supercambioturismo/" target="_blank" rel="noopener noreferrer"><FacebookIcon className={classes.iconFooter} /></a>
  const instagram = <a href="https://www.instagram.com/supercambio/" target="_blank" rel="noopener noreferrer"><InstagramIcon className={classes.iconFooter} /></a>

  const callWhatsapp = () => {
    window.open('https://api.whatsapp.com/send?phone=5511942282300&text=Ol%C3%A1%20operador%20Superc%C3%A2mbio!', '_blank')
  }
  
  return(
    <Box mt={10} pt={2} className={classes.root}>
      <Container>
        <Grid container>
          <Grid item xs={12} md={3}>
            <Box className={classes.title}>Compare câmbio do Dólar</Box>
            <Box mb={1}>
              <a className={classes.link} href="https://www.supercambio.com.br/comprar-dolar?moeda=USD&valor=1000&estado=1&cidade=1&product=cash_rate&voucher=undefined&url_name=dolar&order_type=sale">Dolar em Porto Alegre</a>
            </Box>

            <Box mb={1}>
              <a className={classes.link} href="https://www.supercambio.com.br/comprar-dolar?moeda=USD&valor=1000&estado=2&cidade=38&product=cash_rate&voucher=undefined&url_name=dolar&order_type=sale">Dolar em São Paulo</a>
            </Box>

            <Box mb={1}>
              <a className={classes.link} href="https://www.supercambio.com.br/comprar-dolar?moeda=USD&valor=1000&estado=3&cidade=72&product=cash_rate&voucher=undefined&url_name=dolar&order_type=sale">Dolar em Rio de Janeiro</a>
            </Box>

            <Box mb={1}>
              <a className={classes.link} href="https://www.supercambio.com.br/comprar-dolar?moeda=USD&valor=1000&estado=4&cidade=147&product=cash_rate&voucher=undefined&url_name=dolar&order_type=sale">Dolar em Blumenau</a>
            </Box>

            <Box mb={1}>
              <a className={classes.link} href="https://www.supercambio.com.br/comprar-dolar?moeda=USD&valor=1000&estado=5&cidade=347&product=cash_rate&voucher=undefined&url_name=dolar&order_type=sale">Dolar em Belo Horizonte</a>
            </Box>

            <Box mb={1}>
              <a className={classes.link} href="https://www.supercambio.com.br/comprar-dolar?moeda=USD&valor=1000&estado=6&cidade=351&product=cash_rate&voucher=undefined&url_name=dolar&order_type=sale">Dolar em Curitiba</a>
            </Box>

            <Box mb={1}>
              <a className={classes.link} href="https://www.supercambio.com.br/comprar-dolar?moeda=USD&valor=1000&estado=7&cidade=356&product=cash_rate&voucher=undefined&url_name=dolar&order_type=sale">Dolar em Fortaleza</a>
            </Box>

            <Box mb={1}>
              <a className={classes.link} href="https://www.supercambio.com.br/comprar-dolar?moeda=USD&valor=1000&estado=8&cidade=362&product=cash_rate&voucher=undefined&url_name=dolar&order_type=sale">Dolar em Salvador</a>
            </Box>

            <Box mb={1}>
              <a className={classes.link} href="https://www.supercambio.com.br/comprar-dolar?moeda=USD&valor=1000&estado=9&cidade=363&product=cash_rate&voucher=undefined&url_name=dolar&order_type=sale">Dolar em Brasília</a>
            </Box>

            {/* <Box mb={1}>
              <a className={classes.link} href="">Dolar em Goiânia</a>
            </Box> */}
          </Grid>

          <Grid item xs={12} md={3}>
            <Box className={classes.title}>Compare câmbio do Euro</Box>
            <Box mb={1}>
              <a className={classes.link} href="https://www.supercambio.com.br/comprar-dolar?moeda=EUR&valor=1000&estado=1&cidade=1&product=cash_rate&voucher=undefined&url_name=dolar&order_type=sale">Euro em Porto Alegre</a>
            </Box>

            <Box mb={1}>
              <a className={classes.link} href="https://www.supercambio.com.br/comprar-dolar?moeda=EUR&valor=1000&estado=2&cidade=38&product=cash_rate&voucher=undefined&url_name=dolar&order_type=sale">Euro em São Paulo</a>
            </Box>

            <Box mb={1}>
            <a className={classes.link} href="https://www.supercambio.com.br/comprar-dolar?moeda=EUR&valor=1000&estado=3&cidade=72&product=cash_rate&voucher=undefined&url_name=dolar&order_type=sale">Euro em Rio de Janeiro</a>
            </Box>

            <Box mb={1}>
              <a className={classes.link} href="https://www.supercambio.com.br/comprar-dolar?moeda=EUR&valor=1000&estado=4&cidade=147&product=cash_rate&voucher=undefined&url_name=dolar&order_type=sale">Euro em Blumenau</a>
            </Box>

            <Box mb={1}>
              <a className={classes.link} href="https://www.supercambio.com.br/comprar-dolar?moeda=EUR&valor=1000&estado=5&cidade=347&product=cash_rate&voucher=undefined&url_name=dolar&order_type=sale">Euro em Belo Horizonte</a>
            </Box>

            <Box mb={1}>
              <a className={classes.link} href="https://www.supercambio.com.br/comprar-dolar?moeda=EUR&valor=1000&estado=6&cidade=351&product=cash_rate&voucher=undefined&url_name=dolar&order_type=sale">Euro em Curitiba</a>
            </Box>

            <Box mb={1}>
              <a className={classes.link} href="https://www.supercambio.com.br/comprar-dolar?moeda=EUR&valor=1000&estado=7&cidade=356&product=cash_rate&voucher=undefined&url_name=dolar&order_type=sale">Euro em Fortaleza</a>
            </Box>

            <Box mb={1}>
              <a className={classes.link} href="https://www.supercambio.com.br/comprar-dolar?moeda=EUR&valor=1000&estado=8&cidade=362&product=cash_rate&voucher=undefined&url_name=dolar&order_type=sale">Euro em Salvador</a>
            </Box>

            <Box mb={1}>
              <a className={classes.link} href="https://www.supercambio.com.br/comprar-dolar?moeda=EUR&valor=1000&estado=9&cidade=363&product=cash_rate&voucher=undefined&url_name=dolar&order_type=sale">Euro em Brasília</a>
            </Box>

            {/* <Box mb={1}>
              <a className={classes.link} href="">Euro em Goiânia</a>
            </Box> */}
          </Grid>

          <Grid item xs={12} md={3}>
            <Box className={classes.title}>Acompanhe o histórico</Box>
            <Box mb={1}>
              <a className={classes.link} href="https://www.supercambio.com.br/dolar-hoje?currency=USD&period=1">Dolar Americano hoje</a>
            </Box>
            
            <Box mb={1}>
              <a className={classes.link} href="https://www.supercambio.com.br/dolar-hoje?currency=EUR&period=1">Euro hoje</a>
            </Box>
            
            <Box mb={1}>
              <a className={classes.link} href="https://www.supercambio.com.br/libra-hoje?currency=GBP&period=1">Libra Esterlina hoje</a>
            </Box>
          </Grid>

          <Grid item xs={12} md={3}>
            <Box className={classes.title}>Atendimento</Box>
            <Box className={classes.link}>PORTO ALEGRE</Box>
            <Box className={classes.link}>(51) 99844-4316</Box>
            <Box className={classes.link}>SÃO PAULO E DEMAIS REGIÕES</Box>
            <Box className={classes.link}>(11) 94228-2300</Box>
            <Box className={classes.link}>
              <Button variant="outlined" className={classes.btnWhatsapp} onClick={() => callWhatsapp()} ><WhatsAppIcon className={classes.whatsIconBtn} /> FALE PELO WHATSAPP</Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Box pt={8} pb={6} display="flex" justifyContent="center">
        <Box flexGrow={1} className={classes.icons}>
          {facebook}
          {instagram}
        </Box>
        <Box pr={3}>
          {whatsappIconFooter}
        </Box>
      </Box>
      <Box pb={1} className={classes.supercambioData}>
        Porto Alegre/RS - CNPJ: 24.915.513/0001-90
      </Box>
      {/* <Box ml={40} mr={40}>
        <Divider className={classes.divider} />
      </Box> */}
      {/* <Box pt={1} pb={1}>
        <Container>
          <Grid container>
            <Grid item xs={12} md={3}>
              <Box className={classes.link}>Termos e condições</Box>
            </Grid>

            <Grid item xs={12} md={3}>
              <Box className={classes.link}>Política de Privacidade</Box>
            </Grid>

            <Grid item xs={12} md={3}>
              <Box>
                <a href="https://www.supercambio.com.br/currency_exchange_operators/sign_in" rel="noreferrer" className={classes.link}>Entrar como Corretora de Câmbio</a>
              </Box>
            </Grid>

            <Grid item xs={12} md={3}>
              <Box>
                <a href="https://www.supercambio.com.br/tourism_agency_operators/sign_in" className={classes.link}>Entrar como Parceiro</a>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box> */}
    </Box>
  )
}