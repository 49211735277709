import React, { useState, useRef, useEffect } from 'react'
import { makeStyles, AppBar, Toolbar, Button, Box,  Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem, Divider,
        Hidden, IconButton, Drawer, List, ListItem, ListItemText } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import clsx from 'clsx';
import Logo from '../assets/logo-frase.svg'
import LogoMobile from '../assets/logo-positivo.png'
import { Link } from "react-scroll";

const useStyles = makeStyles((theme) => ({
  appbar: {
    minHeight: 98,
    backgroundColor: '#152b6a',
    justifyContent: 'center',
    alignItems: 'center'
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  menuItem: {
    fontFamily: 'Lato',
    fontSize: 15,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.38,
    color: '#ffffff',
    textTransform:  'uppercase',
    cursor: 'pointer',
  },
  selectToolbar: {
    position: 'relative',
    color: '#ffb200',
    bottom: 3
  },
  newBadge: {
    textTransform:  'lowercase',
    backgroundColor: '#ffb200',
    borderRadius: 7,
    fontSize: 10,
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    justifyContent: 'center',
    alignItems: 'center',
    color: '#152b6a',
    width: 33,    
  },
  expandicon: {
    position: 'relative',
    top: 6
  },
  menuList: {
    backgroundColor: '#152b6a',
    color: '#ffffff',
    fontSize: 14,
    fontFamily: 'Lato'
  },
  dividerNavbar: {
    backgroundColor: '#ffffff!important'
  },
  menuItemSelect: {
    fontSize: 14
  },
  playIcon: {
    fontSize: 20,
    // paddingRight: theme.spacing(1),
    // paddingTop: theme.spacing(1),
    // paddingBottom: theme.spacing(1)
  },
  image: {
    width: '100%'
  }, 
  link: {
    textDecoration: 'none',
    color: '#ffffff',
    "&:visited": { color: "#ffffff" },
    "&:hover": { color: "#ffffff" },
  }
}));

const useStylesMobile = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#152b6a',
    position: 'fixed'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  image: {
    width: '40%'
  },
  imageDrawer: {
    width: '80%',
  },
  imageDrawerBox: {
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  boxImage: {
    width: 'fill-available',
    textAlign: 'center',
    marginRight: theme.spacing(4)
  },
  list: {
    width: 250,
    marginTop: theme.spacing(1)
  },
  link: {
    fontSize: '1rem',
    fontFamily: "Lato",
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: '0.00938em'
  }
}));

export default function Navbar() {
  const classes = useStyles();
  const classesMobile = useStylesMobile();
  const [open, setOpen] = useState(false)
  const [state, setState] = useState({
    left: false,
  });
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <>
    <Box mt={2} ml={2} className={classes.imageDrawerBox}>
      <img src={LogoMobile} alt="Logo" className={classesMobile.imageDrawer} />              
    </Box>
    
    <div
      className={classesMobile.list}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem button component={'a'} href={'https://www.supercambio.com.br'} className={classesMobile.link}>
          <ListItemText primary='Simular' />
        </ListItem>

        <ListItem button component={'a'} href={'https://www.supercambio.com.br/#home_benefits_menu'} className={classesMobile.link}>
          <ListItemText primary='Como Funciona' />
        </ListItem>

        <ListItem button component={'a'} href={'https://bs2.app/360-SuperCambio'} className={classesMobile.link}>
          <ListItemText primary='Conta Internacional' />
        </ListItem>

        <ListItem button component={'a'} href={'https://www.supercambio.com.br/dolar-hoje'} className={classesMobile.link}>
          <ListItemText primary='Dolar Hoje' />
        </ListItem>

        <ListItem button component={'a'} href={'https://www.supercambio.com.br/seguro-viagem'} className={classesMobile.link}>
          <ListItemText primary='Seguro Viagem' />
        </ListItem>

        <ListItem button component={'a'} href={'https://remessa.supercambio.com.br/'} className={classesMobile.link}>
          <ListItemText primary='Remessas' />
        </ListItem>

        <ListItem button className={classesMobile.link} onClick={() => toggleDrawer(anchor, false)}>
          <Link to="boxForm" spy={false} smooth={true} offset={-70} duration={500}>Contato</Link>
        </ListItem>
      </List>
    </div>
    </>
  );

  const simular = <a href="https://www.supercambio.com.br" target="_blank" rel="noopener noreferrer" className={classes.link}>Simular</a>
  const howTo = <a href="https://www.supercambio.com.br/#home_benefits_menu" target="_blank" rel="noopener noreferrer" className={classes.link}><PlayCircleOutlineIcon className={classes.playIcon} /> Como funciona</a>
  const dolarToday = <a href="https://www.supercambio.com.br/dolar-hoje" target="_blank" rel="noopener noreferrer" className={classes.link}>Dolar hoje</a>
  const travelInsurer = <a href="https://www.supercambio.com.br/seguro-viagem" target="_blank" rel="noopener noreferrer" className={classes.link}>Seguro Viagem</a>
  const moneyShipment = <a href="https://remessa.supercambio.com.br/" target="_blank" rel="noopener noreferrer" className={classes.link}>Remessas</a>

  return (
    <>
      <Hidden only={['md', 'lg', 'xl']}>
        <Box>
          <AppBar position="static" className={classesMobile.root}>
            <Toolbar>
              <IconButton edge="start" className={classesMobile.menuButton} onClick={toggleDrawer('left', true)} color="inherit" aria-label="menu">
                <MenuIcon />                
              </IconButton>
              <Drawer anchor={'left'} open={state['left']} onClose={toggleDrawer('left', false)}>
                  {list('left')}
                </Drawer>
              <Box className={classesMobile.boxImage}>
                <img src={Logo} alt="Logo" className={classesMobile.image} />
              </Box>              
            </Toolbar>
          </AppBar>
        </Box>
      </Hidden>
      
      <Hidden only={['sm', 'xs']}>
        <Box className={classes.root}>
          <AppBar position="static" className={classes.appbar}>
            <Toolbar>          
              <Box pr={4}>
                <img src={Logo} alt="Logo" className={classes.image} />
              </Box>
              <Box pr={3.5} className={classes.menuItem}>
                <Button
                  ref={anchorRef}
                  aria-controls={open ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle}
                >
                  <Box className={clsx(classes.menuItem, classes.selectToolbar)}>
                    Câmbio <Box component="span" className={classes.expandicon}><ExpandMoreIcon /></Box>
                  </Box>
                </Button>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown} className={clsx(classes.menuList)}>
                            <Box component="span" className={classes.menuItemSelect}>
                              <MenuItem onClick={handleClose}>
                                {simular}
                              </MenuItem>
                            </Box>
                            
                            <Divider className={classes.dividerNavbar} />
                            
                            <Box component="span" className={classes.menuItemSelect}>
                              <MenuItem onClick={handleClose}>
                                {howTo}                                
                              </MenuItem>
                            </Box> 
                            
                            <Divider className={classes.dividerNavbar} />
                            
                            <MenuItem onClick={handleClose}>
                              {dolarToday}
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Box>
              {/* <Box pr={3.5} className={classes.menuItem}>
                Conta Internacional <Box component="span" className={classes.newBadge}>novo</Box>
              </Box> */}
              {/* <Box pr={3.5} className={classes.menuItem}>
                {dolarToday}
              </Box> */}
              <Box pr={3.5} className={classes.menuItem}>
                {travelInsurer}
              </Box>
              <Box pr={3.5} className={classes.menuItem}>
                {moneyShipment}
              </Box>
              <Box className={classes.menuItem}>
                <Link to="boxForm" spy={true} smooth={true} offset={-70} duration={500}>Contato</Link>
              </Box>
            </Toolbar>
          </AppBar>
        </Box>
      </Hidden>
    </>
  );
}