import React, { useState } from 'react'
import { Box, Grid, InputLabel, TextField, Button, makeStyles, createStyles  } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';
import InputMask from 'react-input-mask';
import api from '../../service/api'

const useStyles = makeStyles((theme) => createStyles({
  title: {
    fontFamily: 'Lato',
    fontSize: 40,
    fontWeight: 800,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 1,
    color: '#1b2e62'
  },
  subtitle: {
    fontFamily: 'Lato',
    fontSize: 22,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.55,
    color: '#767676'
  },
  button: {
    width: 220,
    height: 57,
    borderRadius: 28.5,
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
    backgroundColor: '#f5a623',
    fontFamily: 'Lato',
    fontSize: 20,
    fontWeight: 900,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#1b2e62',
    textTransform: 'none'
  },
  boxForm: {
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      paddingTop: theme.spacing(5),
      textAlign: 'center'
    }    
  },
  form: {
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(5)
    }
  }
}))

export default function FormContact() {
  const classes = useStyles()
  // const [name, setName] = useState('')
  // const [phone, setPhone] = useState('')
  // const [email, setEmail] = useState('')
  const [user, setUser] = useState({
    name: '',
    phone: '',
    email: ''
  })
  const [confirmMessage, setConfirmMessage] = useState(false)
  const [errors, setErrors] = useState({
    name: '',
    phone: '',
    email: ''
  })

  const sendEmail = async e => {
    var isValid = validate()

    if(isValid) {
      setErrors({
        name: '',
        phone: '',
        email: ''
      })

      let data = {
        name: user.name, 
        phone: user.phone,
        email: user.email
      }

      await api.post('/send-mail-investimento', JSON.stringify(data)).then(() => {
        setUser({...user, name: '', email: '', phone: ''})
      }).catch(function(error) {
        setUser({...user, name: '', email: '', phone: ''})
        console.log(error)
      })
      setConfirmMessage(true)       
      e.preventDefault()
    }   
  }

  const validate = () => {
    let nameError = ''
    let phoneError = ''
    let emailError = ''

    if(!user.name) {
      nameError = 'Nome não pode ficar em branco'
    }

    if(!user.phone) {
      phoneError = 'Telefone não pode ficar em branco'
    }

    if(!user.email) {
      emailError = 'Email não pode ficar em branco'
    }

    if(nameError || phoneError || emailError) {
      setErrors({ name: nameError, phone: phoneError, email: emailError })
      return false
    }

    return true
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setConfirmMessage(false);
  };

  return(
    <Box className={classes.boxForm} id="boxForm">
      <Box className={classes.title}>
        Agende uma reunião conosco preenchendo o formulário.
      </Box>
      {
        confirmMessage &&
        <Box pt={2} pr={5}>          
          <Alert variant="outlined" onClose={handleClose} severity="success">
            Mensagem enviada com sucesso!
          </Alert>          
        </Box>
      }
      
      <Box pt={5} pb={5} className={classes.form}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputLabel htmlFor="name">
              Nome *
            </InputLabel>
            <TextField
              error={errors.name ? true : false}
              helperText={errors.name}
              id="name"
              className={classes.textField}
              value={user.name || ''}
              onChange={(e) => setUser({...user, name: e.target.value})}
              margin="normal"
              variant="filled"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <InputLabel htmlFor="phone">
              Telefone *
            </InputLabel>
            <InputMask mask="(99) 99999-9999" value={user.phone || ''} maskChar={null} className={classes.textField} onChange={(e) => setUser({...user, phone: e.target.value})}>
              {(inputProps) => 
                <TextField
                error={errors.phone ? true : false}
                helperText={errors.phone}
                id="phone"
                margin="normal"
                variant="filled"
                fullWidth
              />}
            </InputMask>
            
          </Grid>

          <Grid item xs={12} md={7}>
            <InputLabel htmlFor="email">
              E-mail *
            </InputLabel>
            <TextField
              error={errors.email ? true : false}
              helperText={errors.email}
              id="email"
              value={user.email || ''}
              className={classes.textField}
              onChange={(e) => setUser({...user, email: e.target.value})}
              margin="normal"
              variant="filled"
              fullWidth
            />
          </Grid>
        </Grid>
        <Box mt={5}>
          <Button variant="contained" className={classes.button} onClick={(e) => sendEmail(e)}>Enviar</Button>
        </Box>
      </Box>
    </Box>
  )
}