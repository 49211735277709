import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core'
import Containternacional from '../../assets/containternacional.png'

const useStyles = makeStyles((theme) => createStyles({
  image: {
    width: '100%'
  }
}))

export default function ImageContact() {
  const classes = useStyles()

  return(
    <img src={Containternacional} alt="Conta Internacional" className={classes.image} />
  )
}